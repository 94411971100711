import React from "react"
import Navbar from  '../components/navbar'

export default () => (
	<div>
		<Navbar />
		<div class="container">
			<h1>Welcome!</h1>
			<p>This is my obligatory portfolio! There's a bunch of links in the navbar to more informative stuff while I fill this out.</p>
			<p>This site was built with Gatsby, React-Bootstrap, and Bootstrap with Ionic Icons.</p>
		</div>
	</div>
)