import React from 'react'
import Helmet from "react-helmet"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

export default () => (
	<Navbar sticky="top" bg="dark" variant="dark">
		<Helmet>
			< link href = "https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css" rel = "stylesheet" />
		</Helmet>
		<Navbar.Brand href = "#home">Nodebotanist</Navbar.Brand> 
		<Nav className = "mr-auto" >
			<Nav.Link href="#"> Home </Nav.Link>
			<Nav.Link href = "experience.html" > Experience </Nav.Link> 
			<Nav.Link href = "projects.html" > Projects </Nav.Link>
		</Nav> 
		<Nav class="social-links">
			<Nav.Link href = "https://github.com/nodebotanist" target="_"> < i class = "icon ion-logo-octocat" ></i></Nav.Link >
			< Nav.Link href = "https://twitter.com/nodebotanist" target = "_" > < i class = "icon ion-logo-twitter" > < /i></Nav.Link >
			< Nav.Link href = "https://linkedin.com/in/nodebotanist" target="_" > < i class = "icon ion-logo-linkedin" > < /i></Nav.Link >
			< Nav.Link href = "https://twitch.tv/nodebotanist" target="_" > < i class = "icon ion-logo-twitch" > < /i></Nav.Link >
			< Nav.Link href = "https://www.npmjs.com/~nodebotanist" target="_"> < i class = "icon ion-logo-npm" > < /i></Nav.Link >
		</Nav>
	</Navbar>
)